#usedby-section {
  height: 230px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#usedby-section h3 {
  text-align: center;
}
#usedby-section div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 900px;
  width: 95%;
  align-items: center;
}

@media screen and (max-width: 800px) {
  #usedby-section {
    height: 400px;
  }

  #usedby-section div {
    flex-direction: column;
  }

  #usedby-section div img {
    margin-bottom: 20px;
  }
}

#usedby-section img {
  height: 100px;
  width: 200px;
  object-fit: contain;
  opacity: 70%;
  filter: grayscale(1);
  transition: all 200ms ease;
}

#usedby-section img:hover {
  opacity: 100%;
  filter: grayscale(0);
}