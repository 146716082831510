.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 10;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw;
  overflow: hidden;
  box-sizing: border-box;
}

.headerImg {
  height: 40px;
  width: 40px;
  margin-right: 20px;
  opacity: 0.75;
  mix-blend-mode: lighten;
}

.headerGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.name {
  font-size: 18pt;
  color: white;
}

.headerLink {
  text-decoration: none;
  color: white;
  margin-left: 40px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.account {
  background-color: var(--underline);
  border-radius: 100%;
  display: flex;
  height: 60px;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  transition: all 200ms ease;
  text-align: center;
}

.navGradient {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 200px;
  background-color: black;
  z-index: 9;
  background: var(--purple);
  background: linear-gradient(
    0deg,
    var(--background-color-transparent) 0%,
    var(--background-color) 100%
  );
}

@media (max-width: 500px) {
  .hideIfSmall {
    display: none;
  }
}
