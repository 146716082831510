@keyframes comeUpIn {
  0% {
    opacity: 0;
    transform: var(--base-rotation) translateY(100px);
  }
  100% {
    opacity: 1;
    transform: var(--base-rotation) translateY(0px);
  }
}

.analytics {
  height: 500px;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.imageContainer {
  height: 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  mask-image: linear-gradient(90deg, black, rgba(0, 0, 0, .2));
  width: 100%;
}

.img {
  position: absolute;
  /* opacity: 0; */
  transform: perspective(1000px) rotateY(45deg);

  border-radius: 5px;
  /* animation: comeUpIn 0.5s ease-out forwards; */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  height: 400px;
}

.analytics img:nth-of-type(2) {
  left: 75px;
  animation-delay: 0.25s;
}

.analytics img:nth-of-type(3) {
  left: 150px;
  animation-delay: 0.5s;
}

.textContainer {
  position: absolute;
  align-items: flex-end;
  justify-content: center;
  display: flex;
  text-align: right;
  flex-direction: column;
  padding-top: 30px;
  padding-right: 5%;
  flex: 1;
  top: 0;
  bottom: 0;
  width: 50%;
  right: 0;
}

.preTag {
  margin-bottom: 10px;
  font-size: 12pt;
}
.mainTag {
  font-size: 32pt;
  font-weight: 900;
  margin-bottom: 0;
  margin-top: 0;
}

.body {
  line-height: 1.5;
  font-weight: 600;
}
