html {
  scroll-behavior: smooth;
  scroll-padding-top: 20vh;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(247, 250, 250); */
  background-color: var(--background-color);
  font-family: "Inter", sans-serif;
  color: white;
}
