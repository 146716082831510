:root {
  --background-color-breakdown: 7, 7, 54;
  --background-color: rgba(var(--background-color-breakdown), 1);
  --background-color-transparent: rgba(var(--background-color-breakdown), 0);
  --background-color-50-transparent: rgba(
    var(--background-color-breakdown),
    0.5
  );
  --underline: #fd3651;
  --light-magenta: #c72c41;
  --magenta: #801336;
  --purple: #510a32;
  --dark-purple: #2d142c;
  --shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  /* --background-color-transparent: rgba(var(--background-color), 1); */
  --hero-height: 125vh;
}

* {
  box-sizing: border-box;
}

@keyframes blurIn {
  0% {
    filter: blur(20px);
  }
  100% {
    filter: blur(0px);
  }
}


#account:hover {
  box-shadow: 2px 2px 5px #2d142c4f;
}

.img-background {
  height: var(--hero-height);
  width: 100vw;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}


#rotating-img {
  /* height: 60vh; */
  width: 70vw;
  max-width: 700px;
  object-fit: contain;
  bottom: 0;
  transform-style: preserve-3d;
  margin: 0 auto;
}


#test-content {
  height: calc(1200px + 100vh);
  background-color: var(--purple);
  width: 100%;
}

.container {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
}

section h3 {
  opacity: 0.5;
  margin-bottom: 30px;
  text-align: center;
}

.starry-gradient {
  width: 100vw;
  height: 200px;
  position: absolute;
  background: var(--background-color);
  background: linear-gradient(
    180deg,
    var(--background-color) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 0;
}
