.perk {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  align-items: center;
}

.perk span {
  display: block;
}

.perk-text {
  margin-left: 15px;
  font-weight: 700;
}
