.heroContainer {
  z-index: 4;
  margin-top: 200px;
  margin-bottom: 200px;
  width: 100vw;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.heroTextContent {
  width: 90%;
  margin: 0 auto;
  color: white;
  max-width: 1000px;
}

.heroTextHeadline {
  color: white;
  font-family: "Inter", sans-serif;
  font-size: 60pt;
  text-shadow: var(--shadow);
  line-height: 113px;
  animation: 1s ease-out 0s 1 blurIn;
  margin-bottom: 0;
  text-shadow: var(--shadow);
}

@media (max-width: 500px) {
  .heroTextHeadline {
    font-size: 40pt;
    line-height: 60px;
  }
}

.heroTextContentSubheader {
  line-height: 1.5;
  text-shadow: 0px 0px 10px rgba(var(--background-color-breakdown), 0.75);
}

.heroHeadlineEmphasis {
  /* text-decoration: underline;
  text-decoration-style: dashed;
  text-underline-offset: 6px;
  text-decoration-color: var(--underline); */
  background-color: var(--underline);
  box-shadow: var(--shadow);
}

.rotationOverlapContainer {
  height: 250px;
}

.rotationContainer {
  perspective: 1000px;
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
