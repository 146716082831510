.container {
  width: 100%;
  position: relative;
}

.background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  box-sizing: border-box;
  /* object-position: center center; */
}

.content {
  display: flex;
  flex-direction: column;
}

.gradientTop {
  height: 25%;
  background: var(--background-color-breakdown);
  background: linear-gradient(180deg, rgba(var(--background-color-breakdown), 1) 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.gradientBottom {
  height: 25%;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(var(--background-color-breakdown), 1) 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
