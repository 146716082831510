footer {
  padding-top: 150px;
  padding-bottom: 30px;
  color: white;
}

.legal {
  opacity: 0.5;
}

.links {
  display: grid;
  gap: 20px;
  margin: 20px auto;
  justify-content: center;
}

.link {
  display: block;
}

footer div {
  margin-bottom: 10px;
}

.externalLinkIcon {
  opacity: 0.75;
  transition: all 0.5s ease;
}

.externalLinkIcon:hover {
  opacity: 1;
}
