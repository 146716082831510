#features {
  margin-top: 60px;
  margin-bottom: 80px;
}

.features-columns {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.features-columns div {
  width: 300px;
}
#features-left {
  margin-right: 20px;
}
