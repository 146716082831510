#benefits {
  color: white;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.dynamic-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}
.dynamic-row-item {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 300px;
}
.dynamic-row-item p {
  line-height: 1.5;
  font-weight: 600;
}

@media screen and (min-width: 768px) {
  .dynamic-row {
    flex-direction: row;
    align-items: flex-start;
  }

  .dynamic-row-item {
    max-width: none;
  }
}

.dynamic-row div {
  margin-left: 10px;
  margin-right: 10px;
}

.dynamic-row h4 {
  font-size: 22pt;
  margin-bottom: 0;
  font-weight: 900;
  letter-spacing: 0.35px;
}
